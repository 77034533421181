export const setDataLocal = (key, data) => {

    if (typeof data !== 'string') {
        data.toString();
    }

    localStorage.setItem(key, data);
};

export const getDataLocal = (key) => {
    return localStorage.getItem(key);
};

export const getJsonLocal = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const setJsonLocal = (key, data) => {

    localStorage.setItem(key, JSON.stringify(data));
};

export const dataLocalClear = () => {
    localStorage.clear();
};

export const getLocalUser = () => {
    return getJsonLocal('user');
};

export const setLocalUser = (data) => {
    return setJsonLocal('user', data);
};

