
import axios from './axiosConfig';
import { getDataLocal, getJsonLocal, setDataLocal } from '../helperFunctions/localstrorage';
import { ConstEnv } from '../data/const';


const config = {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json'
	},
	data: {}
};

const addHeadersRequest = () =>{
	config.headers['Authorization'] = getDataLocal('token')
}

const processResponse = (response) => {
	if (response.headers['authorization']) {
		setDataLocal('token', response.headers['authorization'])
	}

	return response.data
}

export const getEndpoint = () => {
	//return getJsonLocal("environment").endpoint

	let enpoint = window.env.api_endpoints[0].endpoint
	
	if (window.env.env === ConstEnv.DEV) {
		enpoint = window.env.api_endpoints[0].endpointDev
	}

	return enpoint
}

export async function getCollectionPagesByComponents() {
	const url = new URL(
		`${getEndpoint()}getCollectionPagesByComponents`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url });
	return await processResponse(response);
}

export async function getInfoPageByCode(pageCODE) {
	const url = new URL(
		`${getEndpoint()}getInfoPageByCode`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { pageCODE } });
	return await processResponse(response);
}

export async function getDataFiltro(filter, pageCODE, paginationPageNumber = 0, paginationPageSize = 0) {
	const url = new URL(
		`${getEndpoint()}getDataFilter`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { filter, pageCODE, paginationPageNumber, paginationPageSize} });
	return await processResponse(response);
}

export async function saveDynamic(dataForm, pageCODE) {
	const url = new URL(
		`${getEndpoint()}saveDynamic`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { dataForm, pageCODE } });
	return await processResponse(response);
}

export async function editDynamic(dataForm, id, pageCODE) {
	const url = new URL(
		`${getEndpoint()}editDynamic`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { dataForm, id, pageCODE } });
	return await processResponse(response);
}

export async function deleteDynamic(ids, pageCODE) {
	const url = new URL(
		`${getEndpoint()}deleteDynamic`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { ids, pageCODE } });
	return await processResponse(response);
}

export async function getReportExcel(filter, pageCODE) {
	const url = new URL(
		`${getEndpoint()}getGeneralReport`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { filter, pageCODE }, responseType: 'blob' });
	return await processResponse(response);
}

export async function login(username, password) {
	const url = new URL(
		`${getEndpoint()}login`
	);
	const response = await axios({ ...config, url, data: { username, password } });
	return await processResponse(response);
}

export async function getUserRequest() {
	const url = new URL(
		`${getEndpoint()}getUser`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url });
	return await processResponse(response);
}

export async function editUserRequest(dataForm) {
	const url = new URL(
		`${getEndpoint()}editUser`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: dataForm });
	return await processResponse(response);
}

export async function changePasswordUserRequest(dataForm) {
	const url = new URL(
		`${getEndpoint()}changePasswordUser`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: dataForm });
	return await processResponse(response);
}

export async function exportDataByPage(filter, pageCODE) {
	const url = new URL(
		`${getEndpoint()}exportByPage`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { filter, pageCODE }, responseType: 'blob' });
	return await processResponse(response);
}

export async function exportQueryPage(pageCODE) {
	const url = new URL(
		`${getEndpoint()}exportPageQuery`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: { pageCODE }});
	return await processResponse(response);
}

export async function getProductsFavoritesReguest(data) {
	const url = new URL(
		`${getEndpoint()}getProductsFavorites`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: data });
	return await processResponse(response);
}

export async function getProductsRequest(data) {
	const url = new URL(
		`${getEndpoint()}getProducts`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: data });
	return await processResponse(response);
}

export async function RegisterClientRequest(data) {
	const url = new URL(
		`${getEndpoint()}registerClient`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: data });
	return await processResponse(response);
}

export async function SendEmailRequest(data) {
	const url = new URL(
		`${getEndpoint()}sendEmail`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: data });
	return await processResponse(response);
}

export async function getProductById(data) {
	const url = new URL(
		`${getEndpoint()}getProductById`
	);
	await addHeadersRequest()
	const response = await axios({ ...config, url, data: data });
	return await processResponse(response);
}