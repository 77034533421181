import Swal from 'sweetalert2';

export const info = (text = '', title = '', showConfirmButton = false, position = 'top-end', timer = 1999) => {
  showAlert('info', text, title, showConfirmButton, position, timer);
};

export const success = (text = '', title = '', showConfirmButton = false, position = 'top-end', timer = 1999) => {
  showAlert('success', text, title, showConfirmButton, position, timer);
};

export const error = (text = '', title = '', showConfirmButton = false, timer = 5999, position = 'top-end') => {
  showAlert('error', text, title, showConfirmButton, position, timer);
};

export const warning = (text = '', title = '', showConfirmButton = false, timer = 5999, position = 'top-end') => {
  showAlert('warning', text, title, showConfirmButton, position, timer);
};

const showAlert = (icon = 'success', text = '', title = '', showConfirmButton = false, position = 'top-end', timer = 1999) => {
  Swal.fire({
    position: position,
    icon: icon,
    title: title,
    html: text,
    showConfirmButton: showConfirmButton,
    toast: true,
    timer: timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
};

export const showAlertConfirm = async (text = '¿Estás seguro de realizar esta acción?', icon = "warning") => {

  return await Swal.fire({
    title: 'Confirmar acción',
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: 'Sí',
    cancelButtonText: 'Cancelar',
  });
}