import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import './index.css'
import { error } from '../helperFunctions/sweetalert2';
import { login } from '../servicesApi';
import { getJsonLocal, setDataLocal, setJsonLocal, setLocalUser } from '../helperFunctions/localstrorage';

const Login = ({ setIsLoggedIn }) => {

    const [formValuesLogin, setFormValuesLogin] = useState({});
    const [environment, setEnvironment] = useState(window.env.api_endpoints.length === 1 ? window.env.api_endpoints[0] : "");

    const onChangeEnvironment = (event) => {
        let { value } = event.target;

        if (value) {
            let structEnvironment = window.env.api_endpoints.filter(api => api.endpoint === value)[0];

            setEnvironment(structEnvironment);
            setJsonLocal("environment", structEnvironment)
        } else {
            setJsonLocal("environment", {})
            setEnvironment("");
        }

    }

    const handleInputChange = (event) => {
        let { name, value } = event.target;

        setFormValuesLogin({
            ...formValuesLogin,
            [name]: value,
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        login(formValuesLogin.usuario, formValuesLogin.password).then(res => {
            setDataLocal("token", res.token);
            setLocalUser(res.user);
            setIsLoggedIn(true);
        }).catch(err => {
            if (err.response.data) {
                error(err.response.data);
                return;
            }

            error("Error al enviar la petición, por favor contacte a soporte.");
        });
    };

    useEffect(() => {
        if (environment) {
            setJsonLocal("environment", environment)
        }
    }, [])

    return (
        <div className="loginCardCenter  d-flex align-items-center justify-content-center bg-gray-v1-LP">
            <div className="content ">
                <Form onSubmit={handleLogin} className="form-signin  form-row  text-center   d-flex align-items-center justify-content-center">
                    <div className="  mb-5">
                        <img src={window.env.company_images.login ?? '/images/Logo.png'} width={250} className=" text-center" alt="logo AZIMUTH" />
                        <h5 className="form-signin-heading  text-center mt-2 color-gray-v3-LP"><strong>ADM</strong></h5>
                    </div>

                    <hr />
{/* 
                    <Form.Group className="col-12">
                        <Form.Select
                            name="environment"
                            onChange={onChangeEnvironment}
                            required={true}
                            value={environment.endpoint}
                        >
                            <option value="">Selecciona un ambiente</option>
                            {window.env.api_endpoints.map((option) => {
                                return (
                                    <option key={option.name} value={option.endpoint}>
                                        {option.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group> */}

                    <Form.Group className=" col-12 ">
                        {/* <label htmlFor="usuario">Usuario</label> */}
                        <Form.Control type="text" name="usuario" placeholder="Usuario"
                            required onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group className="col-12 mt-4">
                        {/* <label htmlFor="password">Contraseña</label> */}
                        <Form.Control type="password" name="password" placeholder="Contraseña"
                            required onChange={handleInputChange} />
                    </Form.Group>
                    <button type="submit" className="btn btn-lg  btn-block mt-5 customButtonStyle" >Ingresar</button>

                </Form>
            </div>
        </div>

    );
};

export default Login;