import React, { useState, Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Sidebar } from './components/Bars/Sidebar';
import { CustomNavBar } from './components/Bars/Navbar';
import Login from './login';
import { dataLocalClear, getDataLocal } from './helperFunctions/localstrorage';
import FullScreenSpinner from './pagesStatics/landingPage/components/spinner';

// Lazy Loading de componentes
const Home = React.lazy(() => import('./home'));
const Entity = React.lazy(() => import('./pages'));
const UserProfile = React.lazy(() => import('./pagesStatics/userProfile'));
const AddColumnsTableTemplate = React.lazy(() => import('./pagesStatics/generalSettings/addColumnsTableTemplate'));
const AddFieldsToForm = React.lazy(() => import('./pagesStatics/generalSettings/addFieldsToForm'));
const AddPermissions = React.lazy(() => import('./pagesStatics/generalSettings/addPermissions'));
const AppLandingPage = React.lazy(() => import('./pagesStatics/landingPage/appLandingPage'));

function App() {
  const [menuCollapse] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(getDataLocal('token'));

  const logout = () => {
    dataLocalClear();
    setIsLoggedIn(false);
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<FullScreenSpinner  logo={'images/Logo.png'}/>}>
        <Routes>
          <Route path="/NoEncontrada404" element={<h1>Página no encontrada</h1>} />
          <Route path="/*" element={<AppLandingPage />} />
          <Route path="/adm/login" element={isLoggedIn ? <Navigate to="/adm/" replace /> : <Login setIsLoggedIn={setIsLoggedIn} />} />

          <Route
            path="/adm/*"
            element={isLoggedIn ?
              <div className="hold-transition sidebar-mini sidebar-collapse">
                <div className="wrapper">
                  <CustomNavBar logout={logout} />
                  <Sidebar logout={logout} menuCollapse={menuCollapse} />
                  <div className="content-wrapper">
                    <Routes>
                      <Route index element={<Home />} />
                      <Route path="userProfile" element={<UserProfile logout={logout} />} />
                      <Route path="generalSettings/*">
                        <Route path="addPermissions/:rolId" element={<AddPermissions />} />
                        <Route path="addColumnsTable/:tableId" element={<AddColumnsTableTemplate />} />
                        <Route path="addFieldsToForm/:formId" element={<AddFieldsToForm />} />
                      </Route>
                      <Route path=":entity" element={<Entity />} />
                      <Route path="*" element={<Navigate to="/NoEncontrada404" replace />} />
                    </Routes>
                  </div>
                </div>
              </div>
              : <Navigate to="/adm/login" replace />
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
