import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.css'; // Importa los estilos personalizados

function FullScreenSpinner({ logo }) {
    return (
        <div className="fullscreen-loader">
          <img src={logo} alt="Logo de Grupo Azimuth" className="loader-logo" />
        </div>
      );
}

export default FullScreenSpinner;