import { Icon } from '../../icons';
import { Link } from 'react-router-dom';
import { showAlertConfirm } from '../../../helperFunctions/sweetalert2';
import { getJsonLocal } from '../../../helperFunctions/localstrorage';

function CustomNavBar({ logout }) {

	const handlerLogout = () => {
		showAlertConfirm("Esta seguro de cerrar sesión ?", "info").then(res => {
			if (res.isConfirmed) {
				logout();
			}
		});
	}

	return (
		<nav className="navbar navbar-expand navbar-dark bg-dark pb-0">
			<div className="container-fluid ">
				<div className="d-flex justify-content-end w-50 ">
					<p className="m-0 text-center"><strong>{getJsonLocal("environment").name}</strong></p>
				</div>
				<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
					<ul className="navbar-nav">

						<li className="nav-item">
							<Link className="nav-link" to="userProfile">
								<p><Icon iconName="Person" className="nav-icon" /> Cuenta</p>
							</Link>
						</li>

						<li className="nav-item">
							<div className="nav-link"   onClick={() => handlerLogout()}> <Icon iconName="BoxArrowLeft" className="nav-icon" /> Cerrar sesión</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

export { CustomNavBar };
