export const PageTypes = {
    FILTRO_TABLA: "filtro-tabla",
    FILTRO_DASHBOARD_TABLA: "filter-dashboard-table",
    REPORTE: "reporte",
    TABLA: "tabla", 
    PERSONALIZED: "personalized",
};

export const ConstPages = {
    CATALOGO_COLUMNAS : "CatalogoColumnas",
    COLUMNAS_TABLA: "ColumnasTablas",
    CATALOGO_FIELDS : "CatalogoCampos",
    FIELDS_FORM: "CamposForm",
    TABLA: "Tablas",
    ROLES: "RolesyPermisos",
    FORM: "Forms",
    PERMISOS: "Permisos",
    PERMISOS_ROLES: "PermisosRoles",
    PAGES_MENU: "PaginasMenu"
}

export const ConstEnv = {
    DEV: "DEV"
};

const MessageErrorDefault = "Se ha producido un error, por favor intenta más tarde"
export const ConstMessageError = {
    TIMEOUT : {
        code: "ECONNABORTED",
        ifHasMessages: "timeout",
        userMessages: MessageErrorDefault
    }
}


export const ConstRoutesAdm = {
   addFieldsToForm: "/adm/generalSettings/addFieldsToForm/",
   addPermissions: "/adm/generalSettings/addPermissions/",
   editPagesDynamicMigration: "/adm/generalSettings/editPagesDynamicMigration/",
   addColumnsTable: "/adm/generalSettings/addColumnsTable/",
   home: "/adm/",
   login:'/adm/login',
}