import { getCollectionPagesByComponents } from '../../../servicesApi';
import { useEffect, useState } from 'react';
import './index.css';
import { Icon } from '../../icons';
import { Link } from 'react-router-dom';
import { setJsonLocal } from '../../../helperFunctions/localstrorage';
import { error } from '../../../helperFunctions/sweetalert2';
import { ConstRoutesAdm } from '../../../data/const';

function Sidebar({ logout }) {
	const [menuComponents, setMenuComponents] = useState([]);

	useEffect(() => {
		getCollectionPagesByComponents().then(res => {
			setMenuComponents(res);
		}).catch(err => {
			if (err.response && err.response.status === 401) {
				logout();
			} else {
				error(err.message, err.response.statusText);
			}
		});
	}, []);


	return (
		<aside
			className="main-sidebar sidebar-dark-primary elevation-4"
			style={{ position: 'fixed' }}
		>
			<div className="brand-link ml-0" style={{ width: '97%' }}>
				<img
					src={window.env.company_images.sidebar ?? '/bi-logo.png'}
					alt=""
					width="35"
					height="35"
					className="d-inline-block align-top ml-2"
				/>
				<span className="brand-text pl-3">
					 {window.env.app_title ?? 'ADM'}
				</span>
			</div>
			<div className="sidebar">
				<div className="user-panel mt-3 pb-3 mb-3 d-flex">
					<ul
						className="nav nav-pills nav-sidebar flex-column"
						data-widget="treeview"
						role="menu"
					>
						<li className="nav-item" >
							<Link className="nav-link" to={ConstRoutesAdm.home}>
								<Icon iconName="House" className="nav-icon" />
								<p>Home</p>
							</Link>
						</li>
					</ul>
				</div>

				<nav className="mt-2 scrollable-sidebar">
					<ul
						className="nav nav-pills nav-sidebar flex-column "
						data-widget="treeview"
						role="menu"
					>
						{menuComponents.map((component) => {
							return (
								<CollapseComponent 
									pageComponentICON={component.pageComponentICON} 
									pageComponentTypeNAME={component.pageComponentTypeNAME} 
									pageComponentCODE={component.pageComponentCODE} 
									arrayItems={component.PageGroup} 
									arrayComponentChild={component.PageComponentChildGroup} 
									pageComponentNAME={component.pageComponentNAME} 
									key={component.pageComponentCODE} 
									collapsed
								/>
							);
						})}
					</ul>
				</nav>
			</div>

		</aside>
	);
}

const CollapseComponent = ({ 
	pageComponentNAME, 
	pageComponentCODE, 
	pageComponentTypeNAME, 
	pageComponentICON, 
	collapsed ,
	arrayItems = [], 
	arrayComponentChild = [],
	paddingLeft = 0
}) => {
	const [isCollapsed, setIsCollapsed] = useState(collapsed)

	const setSelectMenuComponentes = (Page) => {
		setJsonLocal("page", Page);
	}

	
	if (!arrayItems || arrayItems.length <= 0) {
		return (<></>)
	}

	return (
		<>
			{pageComponentTypeNAME === "dropdown" ?
				<>
					<li className="nav-item"  style={{paddingLeft: `${paddingLeft}px`}} title={pageComponentNAME} key={pageComponentCODE} onClick={() => setIsCollapsed(!isCollapsed)}>

						<a className="nav-link w-100" >
							<Icon iconName={pageComponentICON.String ?? "Person"} className="nav-icon mr-1" />
							<p>{pageComponentNAME}  <Icon iconName={isCollapsed ? "ChevronCompactRight" : "ChevronCompactDown"
							} className="nav-icon" /></p>
						</a>
					</li>

					<div
						className={`collapse__content notification ${isCollapsed ? "collapsed" : "expanded"}`}
						aria-expanded={isCollapsed}
					>

						{arrayItems?.map((Page) => {
							return (

								<li className="nav-item"  style={{paddingLeft: `${paddingLeft+20}px`}} title={Page.pageNAME} key={Page.pageCODE}>

									<Link className="nav-link" to={Page.pageCODE} onClick={setSelectMenuComponentes(Page)}>
										<Icon iconName={Page.pageICON.String ?? "Person"} className="nav-icon mr-1" />
										<p>{Page.pageNAME}</p>

									</Link>
								</li>
							);
						})}
						
                       {arrayComponentChild && arrayComponentChild.map((ComponentChild) => {
							return (
				
								<CollapseComponent 
									pageComponentICON={ComponentChild.pageComponentICON} 
									pageComponentTypeNAME={ComponentChild.pageComponentTypeNAME} 
									pageComponentCODE={ComponentChild.pageComponentCODE} 
									arrayItems={ComponentChild.PageGroup} 
									pageComponentNAME={ComponentChild.pageComponentNAME} 
									key={ComponentChild.pageComponentCODE} 
									paddingLeft = {20}
									collapsed 
								/>
							);
						})}
					</div>
				</>
				:
				<li className="nav-item" title={arrayItems[0].pageNAME} key={arrayItems[0].pageCODE}>
					<Link className="nav-link w-100" to={arrayItems[0].pageCODE} onClick={setSelectMenuComponentes(arrayItems[0])}>
						<Icon iconName={arrayItems[0].pageICON.String ?? "Person"} className="nav-icon" />
						<p>{arrayItems[0].pageNAME}</p>
					</Link>
				</li>

			}
		</>
	);
}

export { Sidebar };
