import axios from 'axios';
import { dataLocalClear } from '../helperFunctions/localstrorage';
import {ConstMessageError, ConstRoutesAdm} from "../data/const";
import { error as sweetalertError } from '../helperFunctions/sweetalert2';
axios.defaults.timeout = window.env.timeout ?? 10000; // 10 segundos

axios.interceptors.request.use(
  (config) => {
    // Aquí puedes realizar cualquier lógica adicional antes de que se envíe la solicitud al servidor.

    return config;
  },
  (error) => {
    // Puedes realizar acciones comunes para errores de solicitud aquí.

    // Importante: Siempre devuelve una promesa rechazada con el error original.
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Puedes realizar cualquier lógica adicional aquí antes de que se resuelva la promesa con la respuesta.
  
    return response;
  },
  (error) => {
    // Puedes realizar acciones comunes para errores de respuesta aquí.

    console.log(error);

    if (error.response){
        const { status } = error.response;
        switch (status) {
            case 401:
                dataLocalClear();
                error.message = "La sesión caducó"
                setTimeout(() => window.location.replace(ConstRoutesAdm.login), 2000);
                break;
            default:
                break;
        }
    }

    if (error.code === ConstMessageError.TIMEOUT.code && error.message.includes(ConstMessageError.TIMEOUT.ifHasMessages)) {
        console.log('Timeout de solicitud:', error);
        sweetalertError(ConstMessageError.TIMEOUT.userMessages);
    }
    
    // Importante: Siempre devuelve una promesa rechazada con el error original.
    return Promise.reject(error);
  }
);

export default axios;